@import url('./variables.css');

header {
    position: relative; /* Ensure header can be adjusted */
    z-index: 1000; /* Place header above the body */
    top: 0; /* Keep it at the top */
    border-bottom: 3px ridge var(--primary-color); /* Changed to ridge */
    display: flex;
    flex-direction: row; /* Force row layout */
    justify-content: space-between; /* Space between items */
    align-items: center; /* Center items vertically */
    text-align: center; /* Center text */
    padding: 0.5rem 1rem; /* Add some padding */
    background-color: var(--background-color); /* Ensure background color */
    overflow-y: hidden; /* Prevent vertical scrolling */
}

footer {
    background-color: var(--background-color);
    border-top: 3px ridge var(--primary-color); /* Changed to ridge */
    margin-top: auto;
    text-align: center;
}

.listItem > a {
    align-items: center;
    background-color: rgba(0 0 0 0) !important;
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    font-size: 2.25rem !important;
    margin: 0;
    padding: 0;
}

.menu-container {
    align-items: center;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column !important;
    justify-content: center;
}

.menu-button {
    background-color: var(--background-color);
    border: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: 1.25rem;
    position: relative;
    top: 0 !important;
    right: 0 !important;
    padding: 0.5rem 1rem;
    transition: color 0.2s ease, transform 0.2s ease, background-color 0.2s ease;
}

.header {
    background-color: var(--background-color);
    border-bottom: 5px ridge var(--primary-color);
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
}

.menu-button:hover {
    background-color: var(--secondary-color); /* Change background on hover */
}

/* Make .nav-container background transparent */
.nav-container {
    align-items: center;
    background-color: transparent !important; /* Set background to transparent */
    border-bottom: 3px ridge var(--primary-color); /* Changed to ridge */
    display: flex;
    justify-content: center;
}

/* Make .nav-items background transparent */
.nav-items {
    align-items: center;
    background-color: transparent !important; /* Set background to transparent */
    display: flex;
    flex: 1; /* Added missing semicolon */
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-x: hidden !important; /* Prevent horizontal scroll */
    overflow-y: hidden !important; /* Prevent vertical scroll */
}

.nav-items a {
    color: var(--text-color);
    display: flex;
    font-family: 'Arial Black', Gadget, sans-serif;
    justify-content: center;
    align-items: center;
    margin: 1.5rem !important;
    padding: 0.5rem 2.5rem;
    text-decoration: none;
    transition: color 0.2s ease, transform 0.2s ease;
}

.nav-items a:hover {
    color: var(--secondary-color);
    transform: scale(1.1);
    overflow-y: hidden; /* Ensure no vertical scroll on hover */
}

.nav-items a:active {
    transform: scale(0.95);
    border-radius: 6px;
    padding: 0 0.5rem;
}

.nav-items .listItem:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease;
}

.nav-items .listItem:active {
  transform: scale(0.95);
  transition: transform 0.2s ease;
}

.nav-link {
    margin: 1.5rem !important;
}

.nav-link.active {
  color: var(--primary-color);
  font-size: 2.5rem !important;
  transform: scale(1.1) !important;
  border: 2.5px ridge var(--primary-color) !important; /* Changed to ridge */
  padding: 0 0.5rem !important;
  border-radius: 6px !important;
  background-color:rgba(0, 0, 136, 0.75) !important;
}


#toggleIcon {
  position: absolute; /* Position it relative to the header */
  top: 1rem; /* Adjust the distance from the top of the header */
  right: 1rem; /* Adjust the distance from the right of the header */
  z-index: 1000; /* Ensure it stays above other elements */
  cursor: pointer;
  width: 50px; /* Adjust size as needed */
  height: 50px;
  background-color: rgba(97, 218, 251, 0.8) !important; /* Optional: Background color */
  border-radius: 6px; /* Optional: Rounded corners */
  padding: 0.25rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Add shadow */
}

/* Universal style for all headings */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Arial Black', Gadget, sans-serif; /* Set a consistent font-family for all headings */
}

#anchor {
    border: 3px ridge var(--primary-color); /* Changed to ridge */
}

@media (max-width: 1024px) {
    .nav-items a {
        font-size: 1.3rem!important;
    }
    .nav-link.active {
        font-size: 1.5rem !important;
        transform: scale(1.1) !important;
        border: 2.5px solid var(--primary-color) !important;
        padding: 0 0.5rem !important;
        border-radius: 6px !important;
        background-color: rgba(0, 0, 136, 0.75) !important;
    }
}

@media (max-width: 900px) {
    header {
        flex-direction: column; /* Switch to column layout for smaller screens */
        justify-content: center; /* Center items */
        align-items: center; /* Center items vertically */
        text-align: center; /* Center text */
    }
    .nav-link.active {
        font-size: 1.5rem !important;
        transform: scale(1.1) !important;
        border: 2.5px solid var(--primary-color) !important;
        padding: 0 0.5rem !important;
        border-radius: 6px !important;
        background-color: rgba(0, 0, 136, 0.75) !important;
    }   
    .nav-items a {
        font-size: 1.5rem!important;
    }
}
