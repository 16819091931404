#footer {
  text-align: center !important;
}

footer.footer {
  background-color: var(--background-color);
  border-top: 5px ridge var(--primary-color); /* Changed to ridge */
  bottom: 0;
  color: var(--text-color);
  padding: 1rem;
  position: relative; /* or static */
  text-align: center;
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Arial Black', Gadget, sans-serif; /* Set a consistent font-family for all headings */
}

@media (max-width: 1200px) {
  footer.footer {
    font-size: 1rem;
    padding: 0.8rem;
  }
}

@media (max-width: 992px) {
  footer.footer {
    font-size: 0.9rem;
    padding: 0.6rem;
  }
}

@media (max-width: 768px) {
  footer.footer {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
}

@media (max-width: 576px) {
  footer.footer {
    font-size: 0.7rem;
    padding: 0.4rem;
  }
}

@media (max-width: 480px) {
  footer.footer {
    font-size: 0.6rem;
    padding: 0.3rem;
  }
}
