.portfolio-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  border: 3.5px ridge var(--primary-color); /* Changed to ridge */
  padding: 3.5rem 7.5rem;
  border-radius: 6px;
  background-color: rgba(0, 0, 59, 0.8);
}

.portfolio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-weight: 600 !important;
  font-size: 2rem;
}

.project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  margin: 0.5rem 1rem;
}

.project-link {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: auto auto auto;
}

#project-link {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1rem; /* 16px */
  width: 100%;
  margin: 0.5rem;
  padding: 1.5rem;
}


.divProject {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem; /* 16px */
  background-color: var(--background-color);
  border-radius: 6px;
  border: 3.5px ridge var(--primary-color) !important; /* Changed to ridge */
}

@media (max-width: 768px) {
  .portfolio-container {
    padding: 1rem 5rem;
  }

  .portfolio {
    font-size: 1.5rem;
    margin: 2rem;
  }

  .project-link {
    padding-left: 0.5rem !important;
    grid-template-columns: auto auto;
  }
}

@media (max-width: 480px) {
  .portfolio-container {
    padding: 1rem;
    margin-top: 1rem;
  }

  .portfolio {
    margin: 1.5rem;
  }

  .project-link {
    display: flex !important;
    flex-direction: column !important;
  }
}
