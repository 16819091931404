:root {
  --primary-color: #61dafb;
  --secondary-color: #0fa9c1;
  --tertiary-color: #124047;
  --background-color: #183250c8;
  --text-color: #ffffff;
  --button-background-color: #214296;
  --button-hover-color: #0fa9c1;
  --button-active-color: #124047;
  --font-family: 'Roboto', sans-serif;
  --font-size-large: 2em;
  --font-size-medium: 1.5em;
  --font-size-small: 1em;
  --padding: 20px;
  --margin: 20px;
}